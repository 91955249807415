import {Card, CardContent, CardHeader} from '@mui/material';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const Dashboard = () => {
    const username = localStorage.getItem('username');
    const sessionId = localStorage.getItem('sessionId');
    return <Card>
        <CardHeader title={`Welcome ${username} to SPI Admin`}/>
        <CardContent>
            SessionId: {sessionId}
        </CardContent>
    </Card>
}
