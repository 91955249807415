import {useAuthProvider, useNotify} from 'react-admin';

const LoginPageRedirect = () => {
    const auth = useAuthProvider();
    console.log('authProvider:', auth);
    const notify = useNotify();
    auth.login({}).catch(() =>
        notify('Invalid email or password')
    );
}


export {LoginPageRedirect};